import React, { Component } from 'react';
import dispatchOrderService from '../../services/DispatchOrderService';
import AsyncSelect from 'react-select/async';
import { concatenateStrings } from "../../services/CommonService";

export default class SearchDespatchReceiver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: null,
            options: []
        };

        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        // Fetch and display the only matching option based on selectedReceiverID
        const id = this.props.defaultReceiverID || this.props.selectedReceiverID || null;
        if (id) {
            this.setState({ selected: { value: id, label: "" } }, () => {
                this.fetchOptions({ ID: id, searchText: "" });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedReceiverID !== this.props.selectedReceiverID) {
            const id = this.props.selectedReceiverID || null;
            this.setState({ selected: { value: id, label: "" } }, () => {
                this.fetchOptions({ ID: id, searchText: "" });
            });
        }
    }

    handleSuccess = (selectedReceiver) => {
        this.setState({ selected: selectedReceiver }, () => {
            this.props.handleSelection(selectedReceiver);
        });
    };

    fetchOptions(req, callback) {
        this.setState({ loading: true });

        dispatchOrderService.getShipmentReceiverList(req)
            .then(response => {
                // Filter options to match only the selectedReceiverID
                let options = response.data
                    .filter(item => item.id === this.props.selectedReceiverID)
                    .map(item => ({
                        label: concatenateStrings(" ", item.addressDescription, item.addressContactName),
                        value: item.id,
                        ...item
                    }));

                if (options.length === 1) {
                    // Automatically select the only available option
                    this.handleSuccess(options[0]);
                }

                this.setState({ options, loading: false });

                if (callback) {
                    callback(options);
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                console.error(error);
            });
    }

    loadOptions(inputValue, callback) {
        if (inputValue && inputValue.length > 2) {
            this.fetchOptions({ ID: null, searchText: inputValue }, callback);
        } else {
            callback(this.state.options);
        }
    }

    render() {
        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    isLoading={this.state.loading}
                    isClearable={false}
                    defaultOptions={this.state.options}
                    loadOptions={this.loadOptions}
                    onChange={this.handleSuccess}
                    value={this.state.selected}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}
